import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserIdleService } from 'angular-user-idle';
import { ConnectionService } from 'ngx-connection-service';
import { interval, Subscription, timer } from 'rxjs';
import { filter, map, share } from "rxjs/operators";
import { environment } from '../environments/environment';
import { IDetect } from 'ngx-barcodeput';
import { CheckInService } from './services/check-in.service';
import { CardDispenserService } from './services/card-dispenser.service';

@Component({
  selector: 'app-root',
  templateUrl: `./app.component${environment.templateKey || ''}.html`,
  styleUrls: [`./app.component.scss`],
  host: {
    "(window:click)": "toggleLanguageMenu(false)",
    "(window:keydown)": "focusQrCodeInput($event)",
  }
})
export class AppComponent {

  private reloadTimes = [
    '03:00:00',
    '06:00:00',
  ];

  private authCodeLength: number = 6;
  private reloadPath = '/call';
  private qrCodeFocus!: Subscription;

  @ViewChild("autoFocus") qrCodeInput!: ElementRef;



  isIdle: boolean = false;
  idleCounter: number = 0;
  invalidCardCounter: number = 0;
  hasConnection: boolean = true;
  hasInvalidCard: boolean = false;
  currentLang: string = '';
  clock: Date = new Date();
  subscriptions: Subscription[] = [];
  isLanguageMenuShown: boolean = false;
  env: any = environment;
  isCheckingIn: boolean = false;
  isCheckingOut: boolean = false;

  port: any = null;
  invalidCardTimer: any = null;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private idleService: UserIdleService,
    private checkInService: CheckInService,
    private connectionService: ConnectionService,
    private cardDispenserService: CardDispenserService
  ) {
    this.initConnectionCheck();
    this.initIdleCheck();
    this.initLanguage();
    this.initClock();
    this.initCardDispenser();
    this.initIsCheckingIn();
    this.initIsCheckingOut();
  }

  private initIsCheckingIn() {
    this.checkInService.isCheckingIn.subscribe((isCheckingIn) => {
      this.isCheckingIn = isCheckingIn;
    });
  }

  private initIsCheckingOut() {
    this.checkInService.isCheckingOut.subscribe((isCheckingOut) => {
      this.isCheckingOut = isCheckingOut;
    });
  }

  private initCardDispenser() {
    this.cardDispenserService.init();
    this.cardDispenserService.hasInvalidCard.subscribe((hasInvalidCard) => {
      if (hasInvalidCard) {
        this.initInvalidCardCounter();
      } else {
        this.clearInvalidCardCounter();
      }
      this.hasInvalidCard = hasInvalidCard;
    })
  }

  private initLanguage() {
    this.currentLang = environment.defaultAppLanguage;
    this.translate.use(this.currentLang);
  }

  private initClock() {
    const timerSub = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe(time => {
        this.clock = time;

        if (this.shouldRealoadApplication()) {
          this.reloadApplication();
        }
      });

    this.subscriptions.push(timerSub)
  }

  private initIdleCheck() {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e) => {
      this.isIdle = false;
      this.idleService.stopWatching();
    });

    this.idleService.onTimerStart().subscribe(count => {
      if (count) {
        this.isIdle = true;
        this.idleCounter = count;
      }
    });
    this.idleService.onTimeout().subscribe(() => {
      this.router.navigate([''])
    });
  }

  private initConnectionCheck() {
    this.connectionService.monitor().subscribe(currentState => {
      const hasNetworkConnection = currentState.hasNetworkConnection;
      const hasInternetAccess = currentState.hasInternetAccess;
      if (hasNetworkConnection && hasInternetAccess) {
        this.hasConnection = true;
      } else {
        this.hasConnection = false;
      }
    });
  }

  private initInvalidCardCounter() {
    this.invalidCardCounter = 10;
    this.invalidCardTimer = interval(1000).pipe(
      map(() => {
        this.cardDispenserService.checkIsInvalidCardRemoved();
        if (this.invalidCardCounter <= 0) {
          this.clearInvalidCardCounter();
          this.cardDispenserService.pullInInvalidCard();
        } else {
          this.invalidCardCounter--;
        }
      })
    ).subscribe();
  }

  private clearInvalidCardCounter() {
    if (this.invalidCardTimer) {
      this.invalidCardTimer.unsubscribe();
    }
  }

  private shouldRealoadApplication() {
    const now = new Date();
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const currentTime = `${hour}:${minute}:${seconds}`;

    for (let reloadTime of this.reloadTimes) {
      if (currentTime == reloadTime) {
        return true;
      }
    }

    return false;
  }

  private reloadApplication() {
    if (this.reloadPath) {
      window.location.href = this.reloadPath;
    } else {
      window.location.reload();
    }
  }

  onQrRead(event: IDetect) {

    if (!event.value || event.type != 'scanner') {
      return;
    }

    if (this.isCheckingIn || this.isCheckingOut) {
      return;
    }

    let authCode = event.value as string;
    if (authCode.length > this.authCodeLength) {
      authCode = authCode.slice(0, this.authCodeLength);
    }


    this.checkInService.isCheckingIn.next(true);
    this.idleService.resetTimer();
    this.checkInService.isCheckingIn.next(true);
    this.checkInService.checkAuthCode(authCode).then((attendee) => {
      this.checkInService.getAttendeeAccessCardByAuthCode(authCode).subscribe(
        (card) => {
          if (card.isAccessCardIssued) {
            this.checkInService.isCheckingIn.next(false);
            this.checkInService.redirectToSuccess(attendee);
          } else {
            this.cardDispenserService.startCardIssuing({ authCode: authCode });
          }
        },
        (ex) => {
          this.checkInService.isCheckingIn.next(false);
          this.checkInService.displayError(ex.status);
        });
    }).catch((ex) => {
      this.checkInService.isCheckingIn.next(false);
      this.checkInService.displayError(ex.status);
    });

    this.qrCodeInput.nativeElement.value = '';
  }

  toggleLanguageMenu(isShowMenu: boolean) {
    this.isLanguageMenuShown = isShowMenu;
  }

  focusQrCodeInput() {
    if (this.qrCodeInput.nativeElement != document.activeElement) {
      this.qrCodeInput.nativeElement.focus();
    }
  }

  closeIdleCounter() {
    this.idleService.resetTimer();
    this.isIdle = false;
  }

  useLanguage(lang: string) {
    this.currentLang = lang;
    this.translate.use(lang);
    this.toggleLanguageMenu(false);
  }

  ngOnDestroy() {
    this.qrCodeFocus.unsubscribe();
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
